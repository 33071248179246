<template>
  <form
    class="form w-100 needs-validation"
    @submit="onSubmitForgotPassword"
    id="forgotPasswordForm"
    novalidate
  >
    <div class="text-center mb-10">
      <h1 class="mb-3">
        {{ $t("ForgotPassword.Title", {}, { locale: this.activeLanguage }) }}
      </h1>
      <div class="text-gray-400 fw-bold fs-4">
        {{
          $t("ForgotPassword.Description", {}, { locale: this.activeLanguage })
        }}
      </div>
    </div>
    <div class="fv-row mb-5">
      <label class="form-label fw-bolder fs-6 required">{{
        $t("BaseModelFields.Email", {}, { locale: this.activeLanguage })
      }}</label>
      <div class="position-relative">
        <input
          class="form-control form-control-lg"
          type="email"
          name="email"
          :autocomplete="$isAutoComplete"
          :spellcheck="$isTextSpellCheck"
          data-autofocus="true"
          required
          :disabled="formPosting"
          @keyup.enter="onSubmitForgotPassword"
          v-model="userData.email"
        />
        <div class="invalid-tooltip">
          {{
            this.$t(
              "FieldWarnings.EmailNotValid",
              {},
              { locale: this.activeLanguage }
            )
          }}
        </div>
      </div>
    </div>
    <div class="row">
      <template v-if="formPosting">
        <div class="col-md-12 d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-md-6">
          <button
            @click="onSubmitForgotPassword"
            type="button"
            class="btn btn-lg btn-primary w-100 mb-2"
          >
            <span class="indicator-label">
              {{
                $t(
                  "BaseModelFields.ForgotPassword",
                  {},
                  { locale: this.activeLanguage }
                )
              }}
            </span>
          </button>
        </div>
        <div class="col-md-6">
          <router-link
            to="/sign-in"
            class="btn btn-lg btn-light fw-bolder w-100"
            >{{
              $t("BaseModelFields.Cancel", {}, { locale: this.activeLanguage })
            }}</router-link
          >
        </div>
      </template>
    </div>
  </form>
</template>
<script>
import $ from "jquery";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  name: "PasswordReset",
  data() {
    return {
      userData: {
        email: null,
        clientUrl: `${window.location.origin}/#/`,
      },
      formPosting: false,
      activeLanguage: localStorage.getItem("activeLang") || this.activeLanguage,
    };
  },
  watch: {
    "$store.state.activeLang"() {
      this.activeLanguage = this.$store.state.activeLang;
      this.changeDocumentTitle();
    },
  },
  mounted() {
    let input = document.querySelector("[data-autofocus='true']");
    if (input) {
      input.focus();
    }
  },
  components: {},
  methods: {
    changeDocumentTitle() {
      this.$root.changeDocumentTitle(
        this.$t("ForgotPassword.Title", {}, { locale: this.activeLanguage })
      );
    },
    getResponseMessage(response) {
      var emailAddress = this.userData.email;
      this.userData.email = "";
      if (response.isOk) {
        // return this.$t(
        //   "ForgotPassword.SuccessMessage",
        //   {},
        //   { locale: this.activeLanguage }
        // ).replaceAll("EMAIL_ADDRESS", emailAddress);
      }
      // return response.message;

      return this.$t(
        "ForgotPassword.SuccessMessage",
        {},
        { locale: this.activeLanguage }
      ).replaceAll("EMAIL_ADDRESS", emailAddress);
    },
    onSubmitForgotPassword(event) {
      var form = $("form#forgotPasswordForm"),
        self = this;
      form.addClass("was-validated");
      event.stopPropagation();
      event.preventDefault();

      if (
        !form[0].checkValidity() ||
        String.isNullOrWhiteSpace(this.userData.email) ||
        this.formPosting
      ) {
        return;
      }

      this.formPosting = true;
      this.$authAxios
        .post("/PasswordReset", {
          email: this.$root.encrypt(this.userData.email, true),
          clientUrl: this.userData.clientUrl,
        })
        .then((result) => {
          this.formPosting = false;
          var response = result.data;
          form.removeClass("was-validated");

          // if (response.isOk) {
          // }

          Swal.fire({
            width: "auto",
            html:
              "<span class='fw-bold fs-5'>" +
              self.getResponseMessage(response) +
              "</span>",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText:
              "<i class='fa fa-times'></i>" +
              this.$t(
                "BaseModelFields.Close",
                {},
                { locale: this.activeLanguage }
              ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
            },
          });
        });
    },
  },
  created() {
    this.changeDocumentTitle();
  },
};
</script>
